<template>
    <!-- Detail Project starts -->
    <section id="blog" class="details-project bg-light-gray text-left" @keydown.esc="backMethods">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- Project List Inner -->
                    <div class="blog-listing-inner heading-space-half">
                        <div class="image">
                            <img src="@/assets/img/solcat/Solutions-Catalog-1110x450.png" :alt="$t('comms_and_digital_section.digital_initiatives_post_1')">
                        </div>
                    </div>
                </div>
                <!-- Project Detail Listing -->
                <div class="col-lg-8 col-md-7">
                    <div class="blog-box heading-space-half m-md-0">
                        <!-- Project List Inner -->
                        <div class="blog-listing-inner news_item wow fadeIn" data-wow-delay="300ms">
                            <div class="news_desc">
                                <h3 class="pb-4 font-weight-normal font-primary text-uppercase" v-html="$t('comms_and_digital_section.digital_initiatives_post_1_content')">
                                </h3>
                                <div class="content-editor" v-html="$t('comms_and_digital_section.digital_initiatives_post_1_detail_content')"></div>
                            </div>
                            <!-- Button Close -->
                            <div class="wow fadeIn" data-wow-delay="600ms">
                                <span class="close-btn-bottom1 kopernik">
                                    <a @click="$router.go(-1)">
                                        <i class="fa fa-arrow-left fa-2x"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Project Widgets -->
                <div class="col-lg-4 col-md-5">
                    <div class="text-left">
                        <!-- Call Out Box -->
                        <div class="widget-parallax heading-space-half mb-0 parallax-setting parallaxie position-relative wow fadeIn" data-wow-delay="500ms">
                            <div class="bg-overlay bg-kopernik2 opacity-7"></div>
                            <div class="search-box position-relative bg-change">
                                <p class="font-secondary color-white text-capitalize mb-3 quote-by-custom-subs">
                                    <b>
                                        {{ $t('comms_and_digital_section.digital_initiatives_post_1_call_out') }}
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Detail Project ends -->
</template>

<script>
export default {
    name: 'Project 1',
    methods: {
        backMethods() {
            this.$router.go(-1)
        }
    }
}
</script>