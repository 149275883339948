<template>
    <!-- Detail Project starts -->
    <section id="blog" class="details-project bg-light-gray text-left" @keydown.esc="backMethods">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- Project List Inner -->
                    <div class="blog-listing-inner heading-space-half">
                        <div class="image">
                            <img src="@/assets/img/lean-experimentation/Solar-Drying-Solutions-1110x450.png" :alt="$t('category_lean.project_2_title')">
                        </div>
                    </div>
                </div>
                <!-- Project Left Listing -->
                <div class="col-lg-8 col-md-7">
                    <div class="blog-box heading-space-half m-md-0">
                        <!-- Project List Inner -->
                        <div class="blog-listing-inner news_item wow fadeIn" data-wow-delay="300ms">
                            <div class="news_desc">
                                <h3 class="pb-4 font-weight-normal font-primary text-uppercase" v-html="$t('category_lean.project_2_title_content')">
                                </h3>
                                <div class="content-editor" v-html="$t('category_lean.project_2_detail_content')"></div>                                
                            </div>
                            <!-- Button Close -->
                            <div class="wow fadeIn" data-wow-delay="600ms">
                                <span class="close-btn-bottom1 kopernik">
                                    <a @click="$router.go(-1)">
                                        <i class="fa fa-arrow-left fa-2x"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Project Widgets -->
                <div class="col-lg-4 col-md-5">
                    <div class="text-left">
                        <!-- Categories -->
                        <div class="widget heading-space-half wow fadeIn" data-wow-delay="400ms">
                            <h5 class="color-black font-weight-600 text-uppercase font-primary">
                                {{ $t('category_lean.category') }}
                            </h5>
                            <div>
                                <div class="categories-name">
                                    <p class="font-16 color-grey">
                                        <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik"> {{ $t('category_lean.category_prototyping') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Detail Project ends -->
</template>

<script>
export default {
    name: 'Project 2',
    methods: {
        backMethods() {
            this.$router.go(-1)
        }
    }
}
</script>