<template>
    <!-- Communications And Community Outreach start -->
    <section class="comms">
        <div id="communications-and-community" class="count-comms-sec">
            <div class="count-comms-overlay"></div>
            <div class="container">
                <div class="row text-center count-comms-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.comms_and_digital') }}
                        </h2>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-4rem">
            <div class="row">
                <div class="col-lg-3">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image" @mouseover="digitalInitatives = true">
                                    <a href="#digitalInitatives" class="scroll">
                                        <img src="@/assets/img/Digital-initiatives-600x400.png" :alt="$t('comms_and_digital_section.digital_initiatives')" class="img-responsive" data-no-retina>
                                        <h5 class="centered-thumb color-white font-weight-600 mb-2 text-capitalize font-primary">
                                            {{ $t('comms_and_digital_section.digital_initiatives') }}
                                        </h5>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image" @mouseover="mediaCoverage = true">
                                    <a href="#mediacoverage" class="scroll">
                                        <img src="@/assets/img/Media-coverage-600x400.png" :alt="$t('comms_and_digital_section.media_coverage')" class="img-responsive" data-no-retina>
                                        <h5 class="centered-thumb color-white font-weight-600 mb-2 text-capitalize font-primary">
                                            {{ $t('comms_and_digital_section.media_coverage') }}
                                        </h5>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image" @mouseover="events = true">
                                    <a href="#events" class="scroll">
                                        <img src="@/assets/img/Event-600x400.png" :alt="$t('comms_and_digital_section.events')" class="img-responsive" data-no-retina>
                                        <h5 class="centered-thumb color-white font-weight-600 mb-2 text-capitalize font-primary">
                                            {{ $t('comms_and_digital_section.events') }}
                                        </h5>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image" @mouseover="digitalOutreach = true">
                                    <a href="#digitaloutreach" class="scroll">
                                        <img src="@/assets/img/Digital-outreach-600x400.png" :alt="$t('comms_and_digital_section.digital_channel')" class="img-responsive" data-no-retina>
                                        <h5 class="centered-thumb color-white font-weight-600 font-18 mb-2 text-capitalize font-primary py-sm-4 m-md-2">
                                            {{ $t('comms_and_digital_section.digital_channel') }}
                                        </h5>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Communications And Community Outreach ends -->

    <!-- Digital Initiatives start -->
    <section class="comms" v-show="digitalInitatives">
        <div id="digitalInitatives" class="count-digital-sec">
            <div class="count-digital-overlay"></div>
            <div class="container">
                <div class="row text-center count-digital-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.digital_initiatives') }}
                        </h2>
                        <p class="text-des">
                            {{ $t('comms_and_digital_section.digital_initiatives_subs') }}
                        </p>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" id="digital-post">
            <div class="row">
                <div class="col-sm-12">
                    <div class="category owl-team owl-carousel text-center wow fadeInUp">
                        <div class="item">
                            <div class="team-data-img wow fadeInUp" data-wow-delay="450ms">
                                <div class="image">
                                    <router-link :to="{name: 'Digital-project-1'}" class="scroll text-white">
                                        <img src="@/assets/img/solcat/Solutions-Catalog-650x340.png" :alt="$t('comms_and_digital_section.digital_initiatives_post_1')">
                                        <h5 class="centered-thumb color-white font-weight-700 mb-2 text-capitalize font-secondary">
                                            {{ $t('comms_and_digital_section.digital_initiatives_post_1') }}
                                        </h5>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="team-data-img wow fadeInDown" data-wow-delay="450ms">
                                <div class="image">
                                    <router-link :to="{name: 'Digital-project-2'}" class="scroll text-white">
                                        <img src="@/assets/img/vr4sdgs/VRfprSDGs-650x340.png" :alt="$t('comms_and_digital_section.digital_initiatives_post_2')">
                                        <h5 class="centered-thumb color-white font-weight-700 mb-2 text-capitalize font-secondary">
                                            {{ $t('comms_and_digital_section.digital_initiatives_post_2') }}
                                        </h5>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Digital Initiatives ends -->

    <!-- Media Coverage start -->
    <section class="comms" v-show="mediaCoverage">
        <div id="mediacoverage" class="count-media-sec">
            <div class="count-media-overlay"></div>
            <div class="container">
                <div class="row text-center count-media-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.media_coverage') }}
                        </h2>
                        <p class="text-des">
                            {{ $t('comms_and_digital_section.media_coverage_subs') }}
                        </p>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-sm-4">
            <div class="row">
                <div class="col-sm-12">
                    <div class="main-content-media">
                        <div class="owl-carousel owl-theme">
                            <div class="item">
                                <a target="_blank" href="https://www.thejakartapost.com/academia/2020/03/11/a-womans-success-is-everybodys-success.html">
                                    <img src="@/assets/img/media-coverage/JAKARTA-POST-967x683.png" :alt="$t('comms_and_digital_section.media_coverage_post1')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.thejakartapost.com/academia/2020/03/11/a-womans-success-is-everybodys-success.html">
                                        {{ $t('comms_and_digital_section.media_coverage_post1') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.nowbali.co.id/innovation-to-the-rescue-fighting-covid-with-technology/">
                                    <img src="@/assets/img/media-coverage/NOW-Bali-967x683.png" :alt="$t('comms_and_digital_section.media_coverage_post2')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.nowbali.co.id/innovation-to-the-rescue-fighting-covid-with-technology/">
                                        {{ $t('comms_and_digital_section.media_coverage_post2') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://nasional.tempo.co/read/1376226/energi-di-tengah-pandemi">
                                    <img src="@/assets/img/media-coverage/TEMPO-967x683.png" :alt="$t('comms_and_digital_section.media_coverage_post3')">
                                </a>
                            <div class="desc font-secondary">
                                <a target="_blank" href="https://nasional.tempo.co/read/1376226/energi-di-tengah-pandemi">
                                    {{ $t('comms_and_digital_section.media_coverage_post3') }}
                                </a>
                            </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.thejakartapost.com/academia/2021/03/05/digital-donations---new-potential-to-accelerate-philanthropy-.html">
                                    <img src="@/assets/img/media-coverage/The-Jakarta-Post-967x683-09.jpg" :alt="$t('comms_and_digital_section.media_coverage_post4')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.thejakartapost.com/academia/2021/03/05/digital-donations---new-potential-to-accelerate-philanthropy-.html">
                                        {{ $t('comms_and_digital_section.media_coverage_post4') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://avpn.asia/blog/finding-alternative-financing-by-partnering-with-unusual-suspects/">
                                    <img src="@/assets/img/media-coverage/AVPN-Blog-967x683.png" :alt="$t('comms_and_digital_section.media_coverage_post5')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://avpn.asia/blog/finding-alternative-financing-by-partnering-with-unusual-suspects/">
                                        {{ $t('comms_and_digital_section.media_coverage_post5') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="owl-theme">
                            <div class="owl-controls">
                                <div class="custom-nav owl-nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Media Coverage ends -->

    <!-- Events start -->
    <section class="comms" v-show="events">
        <div id="events" class="count-events-sec">
            <div class="count-events-overlay"></div>
            <div class="container">
                <div class="row text-center count-events-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.events') }}
                        </h2>
                        <p class="text-des">
                            {{ $t('comms_and_digital_section.events_subs') }}
                        </p>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-sm-4">
            <div class="row">
                <div class="col-sm-12">
                    <div class="main-content-events">
                        <div class="owl-carousel owl-theme">
                            <div class="item">
                                <a href="javascript:void(0)">
                                    <img src="@/assets/img/events/AVPN-Southeast-Asia-Summit-967x683.png" :alt="$t('comms_and_digital_section.events_post_1')">
                                </a>
                                <div class="desc font-secondary">
                                    {{ $t('comms_and_digital_section.events_post_1') }}
                                </div>
                            </div>
                            <div class="item">
                                <a href="javascript:void(0)">
                                    <img src="@/assets/img/events/Asahi-World-Forum-967x683.png" :alt="$t('comms_and_digital_section.events_post_2')">
                                </a>
                                <div class="desc font-secondary">
                                    {{ $t('comms_and_digital_section.events_post_2') }}
                                </div>
                            </div>
                            <div class="item">
                                <a href="javascript:void(0)">
                                    <img src="@/assets/img/events/CoronaConcert-967x683.png" :alt="$t('comms_and_digital_section.events_post_3')">
                                </a>
                                <div class="desc font-secondary tokyo">
                                    {{ $t('comms_and_digital_section.events_post_3') }}
                                </div>
                            </div>
                            <div class="item">
                                <a href="javascript:void(0)">
                                    <img src="@/assets/img/events/Solutions-Catalog-Launch-967x683.png" :alt="$t('comms_and_digital_section.events_post_4')">
                                </a>
                                <div class="desc font-secondary">
                                    {{ $t('comms_and_digital_section.events_post_4') }}
                                </div>
                            </div>
                            <div class="item">
                                <a href="javascript:void(0)">
                                    <img src="@/assets/img/events/GoPay-Digital-Donation-Outlook-967x683.png" :alt="$t('comms_and_digital_section.events_post_5')">
                                </a>
                                <div class="desc font-secondary">
                                    {{ $t('comms_and_digital_section.events_post_5') }}
                                </div>
                            </div>
                            <div class="item">
                                <a href="javascript:void(0)">
                                    <img src="@/assets/img/events/The-Impact-of-COVID-19-967x683.png" :alt="$t('comms_and_digital_section.events_post_6')">
                                </a>
                                <div class="desc font-secondary">
                                    {{ $t('comms_and_digital_section.events_post_6') }}
                                </div>
                            </div>
                        </div>
                        <div class="owl-theme">
                            <div class="owl-controls">
                                <div class="custom-nav owl-nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Events ends -->

    <!-- Digital Channels And Outreach start -->
    <section class="comms" v-show="digitalOutreach">
        <div id="digitaloutreach" class="count-outreach-sec" @mouseover="fetchPluginsCount">
            <div class="count-outreach-overlay"></div>
            <div class="container">
                <div class="row text-center count-outreach-content">
                    <div class="col-12 pb-md-5 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.digital_channel') }}
                        </h2>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container pt-sm-5">
            <div class="row text-center count-content">
                <div class="col-12 col-lg-4 count-li wow fadeInUp">
                    <h2 class="num font-kopernik font-primary">
                        <vue3-autocounter ref="counter1" :startAmount="0" :endAmount="value1" :duration="3" prefix="" suffix="+" separator="," decimalSeparator="" :decimals="0" :autoinit="true" />
                    </h2>
                    <h4 class="heading font-secondary">
                        {{ $t('comms_and_digital_section.digitaL_outreach_value_1') }}
                    </h4>
                    <span class="fly-line"></span>
                </div>
                <div class="col-12 col-lg-4 count-li wow fadeInUp">
                    <h2 class="num font-kopernik font-primary">
                        <vue3-autocounter ref="counter2" :startAmount="0" :endAmount="value2" :duration="3" prefix="" suffix="+" separator="," decimalSeparator="" :decimals="0" :autoinit="true" />
                    </h2>
                    <h4 class="heading font-secondary">
                        {{ $t('comms_and_digital_section.digitaL_outreach_value_2') }}
                    </h4>
                    <span class="fly-line"></span>
                </div>
                <div class="col-12 col-lg-4 count-li wow fadeInUp">
                    <h2 class="num font-kopernik font-primary">
                        <vue3-autocounter ref="counter3" :startAmount="0" :endAmount="value3" :duration="3" prefix="" suffix="+" separator="," decimalSeparator="" :decimals="0" :autoinit="true" />
                    </h2>
                    <h4 class="heading font-secondary">
                        {{ $t('comms_and_digital_section.digitaL_outreach_value_3') }}
                    </h4>
                    <span class="fly-line"></span>
                </div>
            </div>
        </div>
    </section>
    <!-- Digital Channels And Outreach ends -->
</template>

<script>
export default {
    name: "Comms-Section",
    data() {
        return {
            digitalInitatives: false,
            events: false,
            digitalOutreach: false,
            mediaCoverage: false,
            /* digital outreach */
            value1: 50000,
            value2: 12000,
            value3: 150,
            duration: 15000,
        }
    },
    methods: {
        fetchPluginsCount() {
            this.$refs.counter1.start()
            this.$refs.counter2.start()
            this.$refs.counter3.start()
        }
    }
}
</script>