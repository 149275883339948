<template>
    <!-- Partners and Supporters start -->
    <section class="partners bg-light-gray1">
        <div id="partners" class="count-partners-sec">
            <div class="count-partners-overlay"></div>
            <div class="container">
                <div class="row text-center count-partners-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('partners_section.title') }}
                        </h2>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 text-center pr-lg-0 md-mb-5 wow fadeInLeft">
                    <div class="price-item price-transform">
                        <div class="price-description" style="padding-bottom: 86px;">
                            <p>
                                <a href="https://www.1000daysfund.org/" target="_blank">
                                    1000 Days Fund
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    Akhmad Tanzi
                                </a>
                            </p>
                            <p>
                                <a href="https://www.alliedcrowds.com/" target="_blank">
                                    Allied Crowds Ltd
                                </a>
                            </p>
                            <p>
                                <a href="https://asiaphilanthropycircle.org/" target="_blank">
                                    Asia Philanthropy Circle (APC)
                                </a>
                            </p>
                            <p>
                                <a href="https://www.diskes.baliprov.go.id/" target="_blank">
                                    Bali Department of Health (Dinkes Prov. Bali)
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    Bali Military Area Command of the Indonesian Army
                                </a>
                            </p>
                            <p>
                                <a href="https://bengkelappek.org/" target="_blank">
                                    Bengkel APPek
                                </a>
                            </p>
                            <p>
                                <a href="https://britcham.or.id/" target="_blank">
                                    British Chamber Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://www.climateandlandusealliance.org/" target="_blank">
                                    Climate and Land Use Alliance
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    Conrad & Angus
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    CV Seni Echo
                                </a>
                            </p>
                            <p>
                                <a href="https://www.dlight.com/" target="_blank">
                                    d.light design
                                </a>
                            </p>
                            <p>
                                <a href="http://www.daiwa-grp.jp/english/" target="_blank">
                                    Daiwa Securities Group Inc.
                                </a>
                            </p>
                            <p>
                                <a href="https://www.diageo.com/" target="_blank">
                                    Diageo
                                </a>
                            </p>
                            <p>
                                <a href="https://www.netherlandsandyou.nl/" target="_blank">
                                    Embassy of Kingdom of Netherlands
                                </a>
                            </p>
                            <p>
                                <a href="https://europa.eu/european-union/index_en" target="_blank">
                                    European Union
                                </a>
                            </p>
                            <p>
                                <a href="https://corporate.exxonmobil.com/" target="_blank">
                                    ExxonMobil Foundation
                                </a>
                            </p>
                            <p>
                                <a href="https://www.fordfoundation.org/" target="_blank">
                                    Ford Foundation
                                </a>
                            </p>
                            <p>
                                <a href="https://fpl.or.id/" target="_blank">
                                    Forum Pengada Layanan
                                </a>
                            </p>
                            <p>
                                <a href="https://www.gsj.org/" target="_blank">
                                    GAMELAN SEKAR JAYA INC US
                                </a>
                            </p>
                            <p>
                                <a href="https://www.gojek.com/gopay/" target="_blank">
                                    GOJEK
                                </a>
                            </p>
                            <p>
                                <a href="https://www.grandchallenges.ca/" target="_blank">
                                    Grand Challenges Canada
                                </a>
                            </p>
                            <p>
                                <a href="https://en.ekonomihijaupapua.org/" target="_blank">
                                    Green Economic Growth for Papua Provinces
                                </a>
                            </p>
                            <p>
                                <a href="http://IDEO.org" target="_blank">
                                    IDEO.org
                                </a>
                            </p>
                            <p>
                                <a href="https://indonesiaindahfoundation.org/id/" target="_blank">
                                    Indonesia Indah Foundation
                                </a>
                            </p>
                            <p>
                                <a href="http://www.idionline.org/" target="_blank">
                                    Ikatan Dokter Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://ibi.or.id/" target="_blank">
                                    Ikatan Bidan Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://visinema.co/" target="_blank">
                                    Visinema Pictures
                                </a>
                            </p>
                            <p>
                                <a href="http://www.ayo-indonesia.org/" target="_blank">
                                    Yayasan AYO Indonesia
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 text-center px-lg-0 md-mb-5 wow fadeInUp">
                    <div class="price-item price-transform">
                        <div class="price-description">
                            <p>
                                <a href="javascript:void(0)">
                                    Aisyiyah
                                </a>
                            </p>
                            <p>
                                <a href="http://akarumput.com/en" target="_blank">
                                    Akarumput
                                </a>
                            </p>
                            <p>
                                <a href="https://www.alamsantidesign.com/" target="_blank">
                                    Alam Santi
                                </a>
                            </p>
                            <p>
                                <a href="https://bpbd.baliprov.go.id/" target="_blank">
                                    BPBD BALI
                                </a>
                            </p>
                            <p>
                                <a href="https://embunnatural.com/" target="_blank">
                                    Embun Natural
                                </a>
                            </p>
                            <p>
                                <a href="https://indosole.com/" target="_blank">
                                    Indosole
                                </a>
                            </p>
                            <p>
                                <a href="https://www.isdb.org/" target="_blank">
                                    Islamic Development Bank (ISDB)
                                </a>
                            </p>
                            <p>
                                <a href="http://www.jica.go.jp/" target="_blank">
                                    Japan International Cooperation Agency (JICA)
                                </a>
                            </p>
                            <p>
                                <a href="https://gemawan.org/en/" target="_blank">
                                    Lembaga Gemawan
                                </a>
                            </p>
                            <p>
                                <a href="https://lp2m.or.id/" target="_blank">
                                    LP2M
                                </a>
                            </p>
                            <p>
                                <a href="https://ltsi.co.uk/" target="_blank">
                                    LTS Internasional
                                </a>
                            </p>
                            <p>
                                <a href="https://www.mampu.or.id/en/" target="_blank">
                                    MAMPU
                                </a>
                            </p>
                            <p>
                                <a href="https://migrantcare.net/" target="_blank">
                                    Migrant CARE
                                </a>
                            </p>
                            <p>
                                <a href="https://www.mofa.go.jp/" target="_blank">
                                    Ministry of Foreign Affairs of Japan
                                </a>
                            </p>
                            <p>
                                <a href="https://www.nationalgeographic.org/" target="_blank">
                                    National Geographic Society 
                                </a>
                            </p>
                            <p>
                                <a href="https://www.naviculamusic.com/" target="_blank">
                                    Navicula
                                </a>
                            </p>
                            <p>
                                <a href="https://www.o-i.com/" target="_blank">
                                    O-I Glass
                                </a>
                            </p>
                            <p>
                                <a href="https://www.ooyoo.co.jp/en/" target="_blank">
                                    OOYOO Co., Ltd.
                                </a>
                            </p>
                            <p>
                                <a href="https://aip-prisma.or.id/" target="_blank">
                                    Palladium
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    Pierre Alain Florent Menu
                                </a>
                            </p>
                            <p>
                                <a href="https://ppni-inna.org/" target="_blank">
                                    Persatuan Perawat Nasional Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://www.gov.pl/" target="_blank">
                                    Polish Embassy
                                </a>
                            </p>
                            <p>
                                <a href="https://pulselabjakarta.org/" target="_blank">
                                    Pulse Lab Jakarta
                                </a>
                            </p>
                            <p>
                                <a href="https://www.pertamina.com/" target="_blank">
                                    PT Pertamina
                                </a>
                            </p>
                            <p>
                                <a href="https://russellinvestments.com/jp/about-us/overview" target="_blank">
                                    Russell Investments Japan Co., Ltd.
                                </a>
                            </p>
                            <p>
                                <a href="https://samsarabali.com/" target="_blank">
                                    Samsara
                                </a>
                            </p>
                            <p>
                                <a href="https://www.senimancoffee.com/" target="_blank">
                                    Seniman Coffee Studio
                                </a>
                            </p>
                            <p>
                                <a href="https://www.packard.org/" target="_blank">
                                    The Packard Foundation
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    Yayasan Pengkajian Pemberdayaan Masyarakat (YKPM)
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 text-center pl-lg-0 wow fadeInRight">
                    <div class="price-item price-transform">
                        <div class="price-description" style="padding-bottom: 56px;">
                            <p>
                                <a href="https://www.dfat.gov.au/" target="_blank">
                                    DFAT
                                </a>
                            </p>
                            <p>
                                <a href="https://econusa.id/en/" target="_blank">
                                    Econusa
                                </a>
                            </p>
                            <p>
                                <a href="https://www.haka.or.id/" target="_blank">
                                    HAkA
                                </a>
                            </p>
                            <p>
                                <a href="https://www.kebonvintagecars.id/" target="_blank">
                                    Kebon Vintage
                                </a>
                            </p>
                            <p>
                                <a href="https://rumbia.co.id/" target="_blank">
                                    Rumbia
                                </a>
                            </p>
                            <p>
                                <a href="https://www.signify.com/global/our-company/signify-foundation" target="_blank">
                                    Signify Foundation
                                </a>
                            </p>
                            <p>
                                <a href="https://simavi.org/" target="_blank">
                                    Simavi
                                </a>
                            </p>
                            <p>
                                <a href="https://snorkelventure.com/" target="_blank">
                                    Snorkel Venture
                                </a>
                            </p>
                            <p>
                                <a href="https://www.sukkhacitta.com/" target="_blank">
                                    SukkhaCitta
                                </a>
                            </p>
                            <p>
                                <a href="https://theaokc.org/" target="_blank">
                                    The Acts of Kindness Collective
                                </a>
                            </p>
                            <p>
                                <a href="https://www.thebodyshop.co.id/" target="_blank">
                                    The Body Shop Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://www.international.gc.ca/world-monde/funding-financement/cfli-fcil/index.aspx?lang=eng" target="_blank">
                                    The Canada Fund for Local Initiatives
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    The Pratten Foundation
                                </a>
                            </p>
                            <p>
                                <a href="https://www.worldbank.org/en/home" target="_blank">
                                    The World Bank
                                </a>
                            </p>
                            <p>
                                <a href="https://global.toyota/jp/sustainability/esg/challenge2050/challenge6/ecogrant/" target="_blank">
                                        TOYOTA Motor Cooperation
                                </a>
                            </p>
                            <p>
                                <a href="https://tulodo.com/" target="_blank">
                                    Tulodo
                                </a>
                            </p>
                            <p>
                                <a href="https://www.unicef.org/" target="_blank">
                                    UNICEF
                                </a>
                            </p>
                            <p>
                                <a href="https://www.ugm.ac.id/" target="_blank">
                                    Universitas Gajah Mada
                                </a>
                            </p>
                            <p>
                                <a href="https://www.unimelb.edu.au/" target="_blank">
                                    University of Melbourne
                                </a>
                            </p>
                            <p>
                                <a href="https://utamaspicebali.com/" target="_blank">
                                    Utama Spice
                                </a>
                            </p>
                            <p>
                                <a href="https://www.visa.co.id/" target="_blank">
                                    VISA
                                </a>
                            </p>
                            <p>
                                <a href="https://www.wra.org.au/" target="_blank">
                                    World Relief Australia
                                </a>
                            </p>
                            <p>
                                <a href="https://www.wujudkan.id/" target="_blank">
                                    Wujudkan!
                                </a>
                            </p>
                            <p>
                                <a href="http://www.diandesa.org/" target="_blank">
                                    Yayasan Dian Desa
                                </a>
                            </p>
                            <p>
                                <a href="https://kitabisa.com/" target="_blank">
                                    Yayasan Kita Bisa
                                </a>
                            </p>
                            <p>
                                <a href="https://maritimnusantara.org/about" target="_blank">
                                    Yayasan Maritim Nusantara Lestari
                                </a>
                            </p>
                            <p>
                                <a href="https://www.dondersfoundation.org/" target="_blank">
                                    Yayasan Pengembangan Kemanusiaan (YPK) Donders
                                </a>
                            </p>
                            <p>
                                <a href="https://plan-international.or.id/" target="_blank">
                                    Yayasan Plan International Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://www.ccsbali.com/about-ccs/governance" target="_blank">
                                    Yayasan Swamitra International
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Partners and Supporters ends -->
</template>

<script>
export default {
    name: 'Partners-Section'
}
</script>