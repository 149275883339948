<template>
    <!-- Finance Report start -->
    <section class="finance">
        <div id="finance" class="count-sec">
            <div class="count-overlay"></div>
            <div class="container">
                <div class="row text-center count-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('financial_report_section.title') }}
                        </h2>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="table-responsive">
                        <table class="table table-hover mt-5 custom-table">
                            <thead class="text-center">
                                <tr>
                                    <th class="font-secondary font-20" colspan="3">
                                        <b>
                                            {{ $t('financial_report_section.header_1') }}
                                        </b> 
                                        <br>
                                        <i>
                                            {{ $t('financial_report_section.header_2') }}
                                            <sup>*</sup>
                                        </i>
                                    </th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th class="font-weight-bold text-right">{{ $t('financial_report_section.finance_year_2') }}</th>
                                    <th class="font-weight-bold text-right">{{ $t('financial_report_section.finance_year_1') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold bg-blue-kopernik text-white">{{ $t('financial_report_section.finance_post_1') }}</td>
                                    <td colspan="3" class="font-weight-bold text-center">{{ $t('financial_report_section.finance_post_2') }}</td>
                                </tr>
                                <!-- <tr>
                                    <td class="font-weight-bold font-kopernik-blue pl-4">{{ $t('financial_report_section.finance_post_3') }}<sup>**</sup></td>
                                    <td class="font-weight-bold text-right">2,246,459</td>
                                    <td class="font-weight-bold text-right">2,664,459</td>
                                </tr> -->
                                <tr>
                                    <td class="font-weight-bold font-kopernik-blue pl-4">{{ $t('financial_report_section.finance_post_4') }}</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_5') }}</td>
                                    <td class="text-right">420,315</td>
                                    <td class="text-right">180,012</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_6') }}</td>
                                    <td class="text-right">39,684</td>
                                    <td class="text-right">707</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_7') }}</td>
                                    <td class="text-right">1,232,385</td>
                                    <td class="text-right">639,267</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_8') }}</td>
                                    <td class="text-right">136,088</td>
                                    <td class="text-right">96,507</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_9') }}</td>
                                    <td class="text-right">-</td>
                                    <td class="text-right">11,250</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-italic pr-3-5rem" align="right">{{ $t('financial_report_section.finance_post_10') }}</td>
                                    <td class="font-weight-bold text-right">1,828,472</td>
                                    <td class="font-weight-bold text-right">927,744</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><br></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-kopernik-blue pl-4">{{ $t('financial_report_section.finance_post_11') }}</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_12') }}</td>
                                    <td class="text-right">322,975</td>
                                    <td class="text-right">284,702</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-italic pr-3-5rem" align="right">{{ $t('financial_report_section.finance_post_13') }}</td>
                                    <td class="font-weight-bold text-right">322,975</td>
                                    <td class="font-weight-bold text-right">284,702</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><br></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-kopernik-blue pl-4">{{ $t('financial_report_section.finance_post_14') }}</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_15') }}</td>
                                    <td class="text-right">23,571</td>
                                    <td class="text-right">72,651</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_35') }}</td>
                                    <td class="text-right">(214)</td>
                                    <td class="text-right">190,017</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-italic pr-3-5rem" align="right">{{ $t('financial_report_section.finance_post_36') }}</td>
                                    <td class="font-weight-bold text-right">23,357</td>
                                    <td class="font-weight-bold text-right">262,668</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><br></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-kopernik-blue pl-4">{{ $t('financial_report_section.finance_post_16') }}</td>
                                    <td class="font-weight-bold text-right">2,174,805</td>
                                    <td class="font-weight-bold text-right">1,475,114</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><br></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold bg-kopernik2 text-white">{{ $t('financial_report_section.finance_post_18') }}</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-kopernik pl-4">{{ $t('financial_report_section.finance_post_19') }}</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_20') }}</td>
                                    <td class="text-right">274,691</td>
                                    <td class="text-right">230,232</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_21') }}</td>
                                    <td class="text-right">124,992</td>
                                    <td class="text-right">190,919</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-italic pr-3-5rem" align="right">{{ $t('financial_report_section.finance_post_22') }}</td>
                                    <td class="font-weight-bold text-right">399,683</td>
                                    <td class="font-weight-bold text-right">421,151</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><br></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-kopernik pl-4">{{ $t('financial_report_section.finance_post_23') }}</td>
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_24') }}</td>
                                    <td class="text-right">618,312</td>
                                    <td class="text-right">731,920</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_25') }}</td>
                                    <td class="text-right">397,471</td>
                                    <td class="text-right">258,548</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_26') }}</td>
                                    <td class="text-right">35,285</td>
                                    <td class="text-right">14,983</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_27') }}</td>
                                    <td class="text-right">59,219</td>
                                    <td class="text-right">85,104</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_28') }}</td>
                                    <td class="text-right">164,109</td>
                                    <td class="text-right">109,780</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_29') }}</td>
                                    <td class="text-right">147,143</td>
                                    <td class="text-right">23,017</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_30') }}</td>
                                    <td class="text-right">55,045</td>
                                    <td class="text-right">154,342</td>
                                </tr>
                                <tr>
                                    <td class="pl-3-5rem">{{ $t('financial_report_section.finance_post_31') }}</td>
                                    <td class="text-right">47,712</td>
                                    <td class="text-right">94,269</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-italic pr-3-5rem" align="right">{{ $t('financial_report_section.finance_post_32') }}</td>
                                    <td class="font-weight-bold text-right">1,524,296</td>
                                    <td class="font-weight-bold text-right">1,471,962</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><br></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-kopernik">{{ $t('financial_report_section.finance_post_33') }}</td>
                                    <td class="font-weight-bold text-right">1,923,979</td>
                                    <td class="font-weight-bold text-right">1,893,114</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><br></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-kopernik">{{ $t('financial_report_section.finance_post_37') }}</td>
                                    <td class="font-weight-bold text-right">250,825</td>
                                    <td class="font-weight-bold text-right">(417,999)</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><br></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold font-kopernik">{{ $t('financial_report_section.finance_post_38') }}<sup>**</sup></td>
                                    <td class="font-weight-bold text-right">2,246,459</td>
                                    <td class="font-weight-bold text-right">2,664,459</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><br></td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold bg-kopernik text-white">{{ $t('financial_report_section.finance_post_34') }}</td>
                                    <td class="font-weight-bold text-right">2,497,285</td>
                                    <td class="font-weight-bold text-right">2,246,459</td>
                                </tr>
                            </tbody>
                        </table>
                        <ul class="financial">
                            <li>
                                <sup>*</sup>
                                {{ $t('financial_report_section.footer_1') }}
                                <!-- <a href="../assets/documents/Yayasan-Kopernik-Audited-Financial-Report-(2020).pdf" target="_blank">
                                    {{ $t('financial_report_section.footer_link') }}
                                </a>. -->
                                <a v-if="['en', 'jp'].includes(this.$i18n.locale)" href="https://kopernik.info/en/about/reports-financials" target="_blank">
                                    {{ $t('financial_report_section.footer_link') }}
                                </a>
                                <a v-if="this.$i18n.locale == 'id'" href="https://kopernik.info/id/tentang-kami/laporan-tahunan-keuangan" target="_blank">
                                    {{ $t('financial_report_section.footer_link') }}
                                </a>.
                            </li>
                            <li>
                                <sup>**</sup>
                                {{ $t('financial_report_section.footer_2') }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Finance Report ends -->
</template>

<script>
export default {
    name: 'Finance-Section'
}
</script>