/* Import CSS */
import '../public/assets/fonts/FuturaStd-Bold/styles.css'
import '../public/assets/fonts/ProximaNova-Regular/styles.css'
import '../public/assets/vendor/css/bundle.min.css'
import '../public/assets/vendor/css/jquery.fancybox.min.css'
import '../public/assets/vendor/css/owl.carousel.min.css'
import '../public/assets/vendor/css/swiper.min.css'
import '../public/assets/vendor/css/scrollup.css'
import '../public/assets/vendor/css/cubeportfolio.min.css'
import '../public/assets/vendor/css/slick.css'
import '../public/assets/vendor/css/slick-theme.css'
import '../public/assets/consulting/css/style.css'
import '../public/assets/consulting/css/custom.css'
import '../public/assets/consulting/css/navigation.css'
// import '../public/assets/consulting/css/settings.css'

/* Define vue and plugins*/
import { createApp } from 'vue'
import { setupI18n } from './i18n'
import { setupRouter } from './router'
import Vue3Autocounter from 'vue3-autocounter';

/* Page layout and views */
import App from './App.vue'

/* i18n */
import en from './locales/en.json'
import id from './locales/id.json'
import jp from './locales/jp.json'

const i18n = setupI18n({
    globalInjection: true,
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        id,
        jp
    }
})
const router = setupRouter(i18n)

/* Vue init */
const app = createApp(App)
app.component('vue3-autocounter', Vue3Autocounter)
app.use(i18n)
app.use(router)
app.mount('#app')