<template>
  <TopLayouts v-if="$route.meta.topdefault" />
  <TopDetailLayouts v-else />
    <router-view v-slot="{ Component }">
      <keep-alive>
        <transition-page>
          <component :is="Component" />
        </transition-page>
      </keep-alive>
    </router-view>
  <BottomLayouts v-if="$route.meta.footer" />
</template>

<script>
import TopDetailLayouts from './components/layouts/HeaderDetail.vue'
import TopLayouts from './components/layouts/Header.vue'
import BottomLayouts from './components/layouts/Footer.vue'
import TransitionPage from './components/transitions/TransitionPage.vue'
export default {
  name: 'App',
  components: {
    BottomLayouts,
    TopDetailLayouts,
    TopLayouts,
    TransitionPage
  }
}
</script>