<template>
    <!-- Pre Footer start -->
    <section class="about bg-kopernik2">
        <h2 class="d-none">heading</h2>
            <div id="closenote" class="count-sec-close"></div>
            <div class="container">
                <div class="row align-items-center position-relative">
                    <div class="col-lg-12 col-md-8 col-12 mb-5 m-md-0">
                        <div class="pt-5 wow fadeInLeft" data-wow-delay="400ms">
                            <div class="testimonial-item">
                                <div class="testimonial-text">
                                    <div class="quote d-flex justify-content-start mb-4">
                                        <i class="fa fa-quote-left"></i>
                                    </div>
                                    <p class="color-white font-16 font-secondary font-italic font-weight-normal mb-4">
                                        {{ $t('footer_close_note.title') }}
                                    </p>
                                    <div class="quote d-flex justify-content-end mb-3">
                                        <i class="fa fa-quote-right"></i>
                                    </div>
                                    <div class="testimonial-post">
                                        <div class="text-content">
                                            <h4 class="color-white font-secondary font-weight-bold mb-1">- {{ $t('footer_close_note.toshi_ewa') }}</h4>
                                            <p class="color-white mb-2"> {{ $t('footer_close_note.brand') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
    <!-- Pre Footer ends -->
</template>

<script>
export default {
    name: 'Close-Section'
}
</script>