<template>
    <!-- Header start -->
    <header>
        <nav class="navbar navbar-top-default navbar-expand-lg static-nav transparent-bg nav-bottom-line header-icon">
            <div class="container">
                <a :href="`/`+this.$i18n.locale" class="logo" v-if="this.$i18n.locale == 'en'">
                    <img src="@/assets/logos/kopernik-logo-black-new.png" alt="logo" title="Kopernik Logo EN" class="logo-default">
                    <img src="@/assets/logos/kopernik-logo-black-new.png" alt="logo" title="Kopernik Logo EN" class="logo-scrolled">
                </a>
                <a :href="`/`+this.$i18n.locale" class="logo" v-else-if="this.$i18n.locale == 'id'">
                    <img src="@/assets/logos/ID_Tagline_black.png" alt="logo" title="Kopernik Logo ID" class="logo-default">
                    <img src="@/assets/logos/ID_Tagline_black.png" alt="logo" title="Kopernik Logo ID" class="logo-scrolled">
                </a>
                <a :href="`/`+this.$i18n.locale" class="logo" v-else-if="this.$i18n.locale == 'jp'">
                    <img src="@/assets/logos/JP_Tagline_black.png" alt="logo" title="Kopernik Logo JP" class="logo-default">
                    <img src="@/assets/logos/JP_Tagline_black.png" alt="logo" title="Kopernik Logo JP" class="logo-scrolled">
                </a>

                <!-- Switch Language -->
                <ul>
                    <li class="languages">
                        <a>
                            <img src="@/assets/logos/globe_black.png" alt="language" class="languages-default">
                            <img src="@/assets/logos/globe_black.png" alt="language" class="languages-scrolled">
                        </a>
                        <ul class="sub-menu-hover sub-lang">
                            <li v-for="optionLocale in supportLocales" :key="optionLocale">
                                <a v-if="optionLocale != this.$i18n.locale" @click.prevent="setLocale(optionLocale)">
                                    <span class="upper">
                                        {{ optionLocale }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <!-- Side Menu Open Button -->
                <a class="menu_bars d-inline-block menu-bars-setting" id="sidemenu_toggle">
                    <div class="menu-lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
            </div>
        </nav>
        <div class="side-menu">
            <div class="inner-wrapper nav-icon">
                <span class="btn-close" id="btn_sideNavClose"></span>
                <nav class="side-nav w-100">
                    <div class="navbar-nav">
                        <!-- <a href="#home" class="scroll nav-link">home</a> -->
                        <!-- <a href="#experimenting" class="scroll nav-link">experimenting</a> -->
                        <a href="#experimenting" class="scroll nav-link">executive summary</a>
                        <a href="#leanexperimentation" class="scroll nav-link">lean experimentation</a>
                        <a href="#solutionsinaction" class="scroll nav-link">solutions in actions</a>
                        <a href="#finance" class="scroll nav-link">financial report</a>
                        <a href="#partners" class="scroll nav-link">partners</a>
                        <a class="menu-line"><i class="fa fa-angle-right font-14" aria-hidden="true"></i></a>
                    </div>
                </nav>

                <div class="side-footer text-white w-100">
                    <ul class="social-icons-simple">
                        <li class="side-menu-icons">
                            <a href="https://www.facebook.com/thekopernik" target="_blank">
                                <i class="fab fa-facebook-f color-white"></i>
                            </a>
                        </li>
                        <li class="side-menu-icons">
                            <a href="https://twitter.com/thekopernik" target="_blank">
                                <i class="fab fa-twitter color-white"></i>
                            </a>
                        </li>
                        <li class="side-menu-icons">
                            <a href="https://www.instagram.com/kopernik.info/" target="_blank">
                                <i class="fab fa-instagram color-white"></i>
                            </a>
                        </li>
                        <li class="side-menu-icons">
                            <a href="https://id.linkedin.com/company/kopernik" target="_blank">
                                <i class="fab fa-linkedin-in color-white"></i>
                            </a>
                        </li>
                    </ul>
                    <p class="text-white">&copy; 2020 Kopernik</p>
                </div>
            </div>
        </div>
        <a id="close_side_menu" href="javascript:void(0);"></a>
        <!--Side Menu-->
    </header>
    <!-- Header end -->
</template>

<script>
import { SUPPORT_LOCALES } from '../../i18n'
export default {
    name : 'Header',
    data() {
        return {
            supportLocales: SUPPORT_LOCALES
        }
    },
    methods: {
        setLocale(locale) {
            setTimeout(() => {
                this.$i18n.locale = locale
                this.$router.push({
                    params: { locale: locale }
                })
            }, 500)
        }
    }
}
</script>