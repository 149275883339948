<template>
    <!-- Detail Project starts -->
    <section id="blog" class="details-project bg-light-gray text-left" @keydown.esc="backMethods">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- Project List Inner -->
                    <div class="blog-listing-inner heading-space-half">
                        <div class="image">
                            <img src="@/assets/img/lean-experimentation/Protecting-Harvests-Rubber-Tapping-1110x450.png" :alt="$t('category_lean.project_1_title')">
                        </div>
                    </div>
                </div>
                <!-- Project Left Listing -->
                <div class="col-lg-8 col-md-7">
                    <div class="blog-box heading-space-half m-md-0">
                        <!-- Project List Inner -->
                        <div class="blog-listing-inner news_item wow fadeIn" data-wow-delay="300ms">
                            <div class="news_desc">
                                <h3 class="pb-4 font-weight-normal font-primary text-uppercase" v-html="$t('category_lean.project_1_title_content')">
                                </h3>
                                <div class="content-editor" v-html="$t('category_lean.project_1_detail_content')"></div>
                            </div>
                            <!-- Button Close -->
                            <div class="wow fadeIn" data-wow-delay="600ms">
                                <span class="close-btn-bottom1 kopernik">
                                    <a @click="$router.go(-1)">
                                        <i class="fa fa-arrow-left fa-2x"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Project Widgets -->
                <div class="col-lg-4 col-md-5">
                    <div class="text-left">
                        <!-- Categories -->
                        <div class="widget heading-space-half wow fadeIn" data-wow-delay="400ms">
                            <h5 class="color-black font-weight-600 text-uppercase font-primary">
                                {{ $t('category_lean.category') }}
                            </h5>
                            <div>
                                <div class="categories-name">
                                    <p class="font-16 color-grey">
                                        <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik"> {{ $t('category_lean.category_prototyping') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Call Out Box -->
                        <div class="widget-parallax heading-space-half mb-0 parallax-setting parallaxie position-relative wow fadeIn" data-wow-delay="500ms">
                            <div class="bg-overlay bg-kopernik2 opacity-7"></div>
                            <div class="search-box position-relative bg-change">
                                <div class="quote d-flex justify-content-start mb-4">
                                    <i class="fa fa-quote-left"></i>
                                </div>
                                <p class="font-secondary color-white text-white font-16 font-weight-normal mb-4">
                                    {{ $t('category_lean.project_1_quote') }}
                                </p>
                                <div class="quote d-flex justify-content-end mb-3">
                                    <i class="fa fa-quote-right"></i>
                                </div>
                                <h5 class="font-secondary color-white font-weight-bold text-capitalize quote-by-custom">
                                    - {{ $t('category_lean.project_1_quote_by') }}
                                </h5>
                                <p class="font-secondary color-white text-capitalize mb-3 quote-by-custom-subs">
                                    {{ $t('category_lean.project_1_quote_by_subs') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Detail Project ends -->
</template>

<script>
export default {
    name: 'Project 1',
    methods: {
        backMethods() {
            this.$router.go(-1)
        }
    }
}
</script>